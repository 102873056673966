import React from 'react'
// react router
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
// pages
import Home from './Home'
import Developer from './Developer'
import Resume from './Resume'
import Photograpy from './Photography'
import About from './About'
import Privacy from './Privacy'
import Error from './Error'
// navbar
import Navbar from './Navbar'
// import Tutor from './Tutor'
const ReactRouterSetup = () => {
  return (
    <Router>
      <Navbar />
      <Switch>
        <Route exact path='/'>
          <Home />
        </Route>
        <Route path='/software-developer'>
          <Developer />
        </Route>
        <Route path='/resume'>
          <Resume />
        </Route>
        <Route path='/photography'>
          <Photograpy />
        </Route>
        <Route path='/about'>
          <About />
        </Route>
        <Route path='/privacy'>
          <Privacy />
        </Route>
        <Route path='*'>
          <Error />
        </Route>
      </Switch>
    </Router>
  )
}

export default ReactRouterSetup
