import React from 'react'
import {
  FaFacebook,
  FaLinkedin,
  FaInstagram,
  FaGithubSquare,
} from 'react-icons/fa'
export const links = [
  {
    id: 1,
    url: '/',
    text: 'Home',
  },
  {
    id: 2,
    url: '/software-developer',
    text: 'Developer',
  },
  {
    id: 3,
    url: '/photography',
    text: 'Photography',
  },
  {
    id: 4,
    url: '/resume',
    text: 'Resume',
  },
  {
    id: 5,
    url: '/about',
    text: 'About',
  },
]

export const social = [
  {
    id: 6,
    url: 'https://www.linkedin.com/in/luis-mendiola03/',
    icon: <FaLinkedin />,
  },
  {
    id: 7,
    url: 'https://www.instagram.com/luiiis_shoots',
    icon: <FaInstagram />,
  },
  {
    id: 8,
    url: 'https://www.facebook.com/luis.f.mendiola.98',
    icon: <FaFacebook />,
  },
  {
    id: 9,
    url: 'https://www.github.com/luismendiola98',
    icon: <FaGithubSquare />,
  },
]
