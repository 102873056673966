import React, { useState, useEffect } from 'react'
import ReactRouterSetup from './setup/index'
import { Switch, Grid, CssBaseline, Divider } from '@material-ui/core'
import { ThemeProvider, createTheme } from '@material-ui/core/styles'
import { AiOutlinePhone, AiOutlineMail } from 'react-icons/ai'
import { BsFillSunFill, BsFillMoonFill } from 'react-icons/bs'
import { social } from './setup/data'
// import { useGlobalContext } from './setup/context'

const getTheme = () => {
  let globalTheme = localStorage.getItem('darkMode')
  return JSON.parse(globalTheme)
}

function App() {
  const [darkMode, setDarkMode] = useState(getTheme())
  //const { isDarkMode } = useGlobalContext()
  const theme = createTheme({
    palette: {
      type: darkMode ? 'dark' : 'light',
    },
    overrides: {
      MuiDivider: {
        root: {
          margin: '20px',
        },
      },
    },
  })

  const handleChange = (e) => {
    e.preventDefault()
    setDarkMode(!darkMode)
  }

  const getYear = () => {
    let d = new Date()
    return d.getFullYear()
  }

  useEffect(() => {
    localStorage.setItem('darkMode', JSON.stringify(darkMode))
  }, [darkMode])

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline>
        <Grid container direction='column'>
          <div>
            <div className='theme-picker'>
              <BsFillSunFill className={darkMode ? '' : 'hide-icon'} />
              <Switch
                checked={darkMode}
                onClick={handleChange}
                inputProps={{ 'aria-label': 'theme picker' }}
              />
              <BsFillMoonFill className={darkMode ? 'hide-icon' : ''} />
            </div>
            <ReactRouterSetup />
          </div>

          <Divider />
          <div className='footer'>
            <div className='contact-info'>
              <div>
                <strong>Contact Information</strong>
                <br />
                <a href='mailto:luisfmendiola03@gmail.com'>
                  <AiOutlineMail aria-label='email icon' />
                </a>
                <a href='tel:7604309060'>
                  <AiOutlinePhone aria-label='phone icon' />
                </a>
              </div>
              <div>
                <strong>Suggestions</strong>
                <br />
                <a href='mailto:luisfmendiola03@gmail.com'>
                  <AiOutlineMail aria-label='email icon' />
                </a>
              </div>
              <div>
                <strong>Connect with me</strong>
                <br />
                <ul className='footer-social-icons'>
                  {social.map((profile) => {
                    const { id, url, icon } = profile
                    return (
                      <li key={id}>
                        <a
                          href={url}
                          target='_blank'
                          rel='noreferrer'
                          aria-label='social icons'
                        >
                          {icon}
                        </a>
                      </li>
                    )
                  })}
                </ul>
              </div>
            </div>

            <div className='center'>
              © {getYear()} Luis F. Mendiola
              <br />
              Escondido, CA
              <br />
              <a href='/privacy'>Privacy Policy</a>
            </div>
          </div>
        </Grid>
      </CssBaseline>
    </ThemeProvider>
  )
}

export default App
